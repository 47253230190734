<template>
  <app-modal-add-package
    :show="showModalPackage"
    @close="showModalPackage = false"
    @refresh="refresh"
  />
  <transition>
    <div class="flex flex-col md:flex-row">
      <div
        :class="[
          'flex flex-col w-full py-2 md:py-10 px-4 md:px-10',
          showPreview ? 'md:w-1/2' : 'md:w-4/5',
        ]"
      >
        <p class="mb-5 font-semibold text-xl">
          {{ $t("subscription.subscriber.add_subscriber_title") }}
        </p>
        <form
          class="flex flex-col items-start"
          @submit.prevent="createSubscriber"
        >
          <app-form-input
            id="subscriber.name"
            name="subscriber.name"
            type="text"
            :placeholder="$t('subscription.subscriber.name_placeholder')"
            :labelText="$t('subscription.subscriber.name_label')"
            class="w-full mb-5"
            v-model="subscriber.name"
            :errors="errors.name"
          />

          <app-form-input
            class="w-full mb-5"
            type="email"
            :labelText="$t('subscription.subscriber.email_label')"
            :placeholder="$t('subscription.subscriber.email_placeholder')"
            v-model="subscriber.email"
            :errors="errors.email"

          />

          <app-form-telephone-no
            class="w-full mb-16"
            :labelText="$t('subscription.subscriber.phone_no_label')"
            :placeholder="$t('subscription.subscriber.phone_no_placeholder')"
            v-model="subscriber.phone_no"
            :errors="errors.phone_no"
          />

          <p class="font-semibold text-xl mb-8">
            {{ $t("subscription.subscriber.choose_package_title") }}
          </p>

          <app-form-select
            class="w-full mb-16"
            type="text"
            :datas="listPackages"
            :allowButton="true"
            :buttonText="$t('subscription.subscriber.create_package')"
            :showListTitle="true"
            :listTitleText="$t('subscription.subscriber.package_list')"
            :allowFilter="false"
            :labelText="$t('subscription.subscriber.package_for_subscriber_label')"
            :placeholder="$t('subscription.subscriber.choose_package_placeholder')"
            @button-click="showModalPackage = true"
            v-model="subscriber.package"
            :errors="errors.package_id"
          />

          <app-button
            type="submit"
            :loading="loading"
            class="w-full"
            :showProceedIcon="true"

            >{{ $t("subscription.subscriber.submit_subscription_button") }}
          </app-button>

        </form>
      </div>
      <div
        :class="[
          'md:overflow-y-auto md:border-l py-4 md:py-8 px-4 md:px-10 flex flex-col',
          showPreview ? 'md:w-1/2' : 'md:w-1/5',
        ]"
      >
        <div
          :class="[
            'flex flex-col md:flex-row items-center md:space-x-4',
            showPreview ? '' : 'justify-center',
          ]"
        >
          <p
            class="
              w-full
              text-center
              md:text-left md:w-auto
              text-xl
              font-semibold
              mb-2
              md:mb-0
            "
            v-if="showPreview"
          >
            {{ $t("subscription.subscriber.preview_title") }}
          </p>
          <app-button
            :primary="false"
            :showf70Icon="false"
            width="w-full md:w-auto"
            :class="[togglePreview ? 'mb-0' : 'mb-4']"
            @click="togglePreview"
          >
            {{
              showPreview
                ? $t("subscription.subscriber.preview_hide")
                : $t("subscription.subscriber.preview_show")
            }}
          </app-button>
        </div>

        <div
          class="mt-4 md:mt-8 flex flex-col"
          v-if="showPreview"
        >
          <div
            class="
              bg-gray-200
              rounded-t-md
              flex flex-row
              px-1
              py-2
              items-center
              space-x-1
              md:space-x-2
            "
          >
            <div class="flex flex-row space-x-1">
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
              <div class="bg-gray-300 rounded-lg w-2 md:w-3 h-2 md:h-3"></div>
            </div>
            <div
              class="
                flex flex-row
                bg-gray-100
                md:w-full
                rounded-md
                px-1
                py-1
                items-center
              "
            >
              <LockClosedIcon class="w-3 h-3 mr-1" />
              <p class="text-xs">{{ subscriber_url }}</p>
            </div>
          </div>

          <SubscriptionActivationPage :preview_mode="true" class="rounded-b-md" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import SubscriptionActivationPage from "@/views/pages/main/subscription/subscription-activation-page"
export default {
  components: {
    LockClosedIcon,
    SubscriptionActivationPage,
  },
  data() {
    return {
      showPreview: true,
      showModalPackage: false,
    };
  },

  mounted() {
    this.getListPackages();
  },

  computed: {
    loading() {
      return this.$store.getters["subscriptionStore/loading"];
    },

    listPackages() {
      return this.$store.getters["subscriptionStore/list_packages"];
    },

    subscriber() {
      return this.$store.getters["subscriptionStore/subscriber"];
    },

    errors() {
      return this.$store.getters["subscriptionStore/errors"] ?? this.emptyErrors;
    },

    subscriber_url() {
      return (
        process.env.VUE_APP_BASE_URL +
        "subscription/activation/<"+
        this.$t("general.generated_id") +
        ">"
      );
    },
  },

  methods: {
    togglePreview() {
      this.showPreview = !this.showPreview;
    },

    getListPackages() {
      this.$store.dispatch("subscriptionStore/retrievePackagesByBusinessId");
    },

    async createSubscriber(form) {
      this.resetState();

      this.subscriber.package_id = this.subscriber.package.id

      const subscriber = await this.$store.dispatch(
        "subscriptionStore/createOrUpdateSubscriber",
        this.subscriber
      );

      if (subscriber == null) return;

      this.$router.push({
        name: "subscription.subscriber.list",
        params: {
          package_id: this.subscriber.package_id,
          package_name: this.subscriber.package.name 
        },
      });
    },

    refresh(){
      this.getListPackages()
    },


    resetState() {
      this.$store.commit("subscriptionStore/setErrorMessage");
    },

  },
};
</script>